import React from 'react';
import './input.css';

import UnderMaintenancePage from "./comp/mintancesite/UnderMaintenance";
// import Footer from "./comp/footer";


function App() {
  return (
      <div>

          <UnderMaintenancePage/>
          {/*<LandingPage/>*/}
          {/*<Header/>*/}
          {/*<WhyUsSection/>*/}


          {/*<MarqueeDemo/>*/}
          {/*<Footer/>*/}
      </div>
  );
}

export default App;
